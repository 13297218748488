import parseReceivedMessage from "features/parseReceivedMessage";

const SOCKET_API = `wss://8bo92l54e9.execute-api.us-east-1.amazonaws.com/dev`;

const webSocketMiddleware = (store) => {
  let socket = null;
  let last_socket_user_id = null;
  let pendingMessages = [];
  let retryCount = 0;
  const maxRetries = 5;
  const baseDelay = 1000; // 1 second initial backoff

  // Store the heartbeat interval ID
  let heartbeatIntervalId = null;

  const onOpen = () => {
    console.log("WebSocket connected:", socket);

    // Reset retry count on successful connection
    retryCount = 0;

    // ---- Start periodic heartbeat every 8 minutes ----
    // The 10-minute idle timeout means sending any message at least once
    // every 10 minutes. We'll do it at 8 minutes for safety.
    heartbeatIntervalId = setInterval(
      () => {
        if (socket && socket.readyState === WebSocket.OPEN) {
          // A simple ping or heartbeat event
          const heartbeatMsg = {
            type: "heartbeat",
            timestamp: Date.now(),
          };
          socket.send(JSON.stringify(heartbeatMsg));
          console.log("Heartbeat sent:", heartbeatMsg);
        }
      },
      8 * 60 * 1000,
    ); // 8 minutes in ms

    // If we have queued messages, send them now
    if (socket.readyState === WebSocket.OPEN) {
      pendingMessages.forEach((message) => {
        socket.send(JSON.stringify(message));
      });
      pendingMessages = [];
    }
  };

  const onClose = (event) => {
    console.log("WebSocket closed:", event);

    // Clear the heartbeat interval so we don't keep sending
    if (heartbeatIntervalId) {
      clearInterval(heartbeatIntervalId);
      heartbeatIntervalId = null;
    }

    // Decide if we want to reconnect
    const intendedDisconnect =
      event.code === 1000 && event.reason.includes("disconnect event");

    if (!intendedDisconnect && last_socket_user_id !== null) {
      // Attempt to reconnect if we haven't hit max retries
      if (retryCount < maxRetries) {
        retryCount++;
        const delay = Math.min(baseDelay * 2 ** (retryCount - 1), 30000);
        console.log(
          `Reconnecting in ${delay}ms (attempt ${retryCount}/${maxRetries}).`,
        );

        setTimeout(() => {
          console.log("WebSocket reconnecting...");
          socket = new WebSocket(
            SOCKET_API + `?user_id=${last_socket_user_id}`,
          );
          socket.onopen = onOpen;
          socket.onclose = onClose;
          socket.onmessage = onMessage;
          socket.onerror = onError;
        }, delay);
      } else {
        console.log("WebSocket max retries reached. No further attempts.");
      }
    }
  };

  const onError = (error) => {
    console.error("WebSocket error observed:", error);
    // Optionally, you can close and let onClose handle the reconnect
    // socket.close();
  };

  const onMessage = (event) => {
    const { dispatch } = store;
    try {
      const messageData = JSON.parse(event.data);

      // If it's a heartbeat acknowledgement or something you don't care about,
      // you can ignore it. Otherwise parse or dispatch as normal:
      if (messageData.type === "heartbeat_ack") {
        // The server might optionally send "pong". Log or ignore it.
        console.log("Received heartbeat ack from server:", messageData);
        return;
      }

      // Otherwise, handle domain messages
      parseReceivedMessage(
        dispatch,
        messageData,
        getAdditionalStoreData(messageData.action, messageData.vendorCode),
      );
    } catch (err) {
      console.error(
        "Failed to parse incoming WebSocket message:",
        err,
        event.data,
      );
    }
  };

  const getAdditionalStoreData = (action, vendorCode) => {
    if (action === "fetch_products") {
      const state = store.getState();
      return {
        est_delivery_days:
          state?.user?.user?.Distributors?.[vendorCode]?.est_delivery_days,
      };
    }
    return null;
  };

  return (next) => (action) => {
    switch (action.type) {
      case "WEBSOCKET_CONNECT": {
        const user_id = action.payload?.user_id;
        if (
          socket &&
          socket.readyState === WebSocket.OPEN &&
          user_id === last_socket_user_id
        ) {
          console.log("Already connected for this user; skipping re-connect");
          break;
        }

        // If a socket exists, close it
        if (socket) {
          socket.close(1000, "connection close");
        }

        console.log(`Opening WebSocket for user_id=${user_id}`);
        socket = new WebSocket(SOCKET_API + `?user_id=${user_id}`);
        last_socket_user_id = user_id;

        socket.onopen = onOpen;
        socket.onclose = onClose;
        socket.onmessage = onMessage;
        socket.onerror = onError;

        break;
      }

      case "WEBSOCKET_DISCONNECT": {
        console.log("Received WEBSOCKET_DISCONNECT action.");
        if (socket) {
          console.log("Closing WebSocket intentionally.");
          socket.close(1000, "disconnect event connection close");
        }
        socket = null;
        last_socket_user_id = null;
        pendingMessages = [];
        retryCount = 0;

        // Also clear heartbeat if it's running
        if (heartbeatIntervalId) {
          clearInterval(heartbeatIntervalId);
          heartbeatIntervalId = null;
        }

        break;
      }

      case "WEBSOCKET_SEND_MESSAGE": {
        if (!socket || socket.readyState !== WebSocket.OPEN) {
          console.log("Socket not open; adding to pendingMessages.");
          pendingMessages.push(action.payload);
        } else {
          socket.send(JSON.stringify(action.payload));
        }
        break;
      }

      default:
        return next(action);
    }
  };
};

export default webSocketMiddleware;
