import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showUpdatedCartsAlert: false,
  loading: false,
  error: null,
  // Match the schema from README.md
  po_data: [],
  line_items: [],
  // Cart data by distributor
  ab: {
    cartData: {
      items: [],
    },
  },
  sm: {
    cartData: {
      items: [],
    },
  },
  ss: {
    cartData: {
      items: [],
    },
    invoice_ids: {},
  },
  items_not_found: [],
};

const autoAddSlice = createSlice({
  name: "autoAdd",
  initialState,
  reducers: {
    autoAddStart: (state) => {
      state.loading = true;
      state.error = null;
      state.po_data = [];
      // state.line_items = [];
      state.ab = { cartData: { items: [] } };
      state.sm = { cartData: { items: [] } };
      state.ss = { cartData: { items: [] }, invoice_ids: {} };
      state.items_not_found = [];
    },
    autoAddSuccess: (state, action) => {
      state.loading = false;
      // Extract data from the response
      const { po_data, line_items, ab, sm, ss, items_not_found } =
        action.payload;

      // Update state with all the data
      state.showUpdatedCartsAlert = true; 
      state.po_data = po_data || [];
      state.line_items = [...state.line_items, ...(line_items || [])];
      state.ab = ab || { cartData: { items: [] } };
      state.sm = sm || { cartData: { items: [] } };
      state.ss = ss || { cartData: { items: [] }, invoice_ids: {} };
      state.items_not_found = items_not_found || [];
    },
    autoAddError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      // Reset all data on error
      state.showUpdatedCartsAlert = false;
      state.po_data = [];
      // state.line_items = [];
      state.ab = { cartData: { items: [] } };
      state.sm = { cartData: { items: [] } };
      state.ss = { cartData: { items: [] }, invoice_ids: {} };
      state.items_not_found = [];
    },
    resetAutoAdd: (state) => {
      // Reset to initial state
      state.showUpdatedCartsAlert = false;
      state.loading = false;
      state.error = null;
      state.po_data = [];
      state.line_items = [];
      state.ab = { cartData: { items: [] } };
      state.sm = { cartData: { items: [] } };
      state.ss = { cartData: { items: [] }, invoice_ids: {} };
      state.items_not_found = [];
    },
    setShowUpdatedCartsAlert: (state, action) => {
      state.showUpdatedCartsAlert = action.payload;
    },
  },
});

export const {
  autoAddStart,
  autoAddSuccess,
  autoAddError,
  resetAutoAdd,
  setShowUpdatedCartsAlert,
} = autoAddSlice.actions;

// Selectors
export const selectAutoAddLoading = (state) => state.autoAdd.loading;
export const selectAutoAddError = (state) => state.autoAdd.error;
export const selectPOData = (state) => state.autoAdd.po_data;
export const selectLineItems = (state) => state.autoAdd.line_items;
export const selectABCart = (state) => state.autoAdd.ab;
export const selectSMCart = (state) => state.autoAdd.sm;
export const selectSSCart = (state) => state.autoAdd.ss;
export const selectItemsNotFound = (state) => state.autoAdd.items_not_found;
export const selectHasUpdatedCarts = (state) =>
  state.autoAdd.ab.cartData.items.length > 0 ||
  state.autoAdd.sm.cartData.items.length > 0 ||
  state.autoAdd.ss.cartData.items.length > 0;
export const selectShowUpdatedCartsAlert = (state) =>
  state.autoAdd.showUpdatedCartsAlert;

export default autoAddSlice.reducer;
