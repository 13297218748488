import React, { useMemo } from 'react';
import { LinearProgress, Tooltip, Typography } from '@mui/material';
import styles from "./styles.module.scss";

const ShippingProgressBar = ({
  vendorName,
  freeShippingMin,
  discountDSTotalPrice,
}) => {
  const progress = (discountDSTotalPrice / freeShippingMin) * 100;
  let freeShippingProgress = Math.min(progress, 100);
  
  // Refrain from making users think that 99% is 100%
  if (freeShippingProgress >= 95 && freeShippingProgress < 100) {
    freeShippingProgress = 95;
  }
  // If there is no shipping min, max out linear progress bar
  if (!freeShippingMin) {
    freeShippingProgress = 100;
  }

  const reachedShippingMin = freeShippingMin - discountDSTotalPrice <= 0 || !freeShippingMin;
  const remainingAmount = (freeShippingMin || 0) - discountDSTotalPrice;

  const tooltipContent = useMemo(() => (
    <React.Fragment>
      {reachedShippingMin ? (
        <>
          <Typography className="text-base">
            You've reached your free shipping minimum for {vendorName}!
          </Typography>
          <Typography variant="body2" style={{ fontSize: "0.6em" }}>
            Some exclusions may apply. Proceed to checkout or view on
            vendor site for exact shipping estimates.
          </Typography>
        </>
      ) : (
        <>
          <Typography>
            Spend{" "}
            <strong>{` $${remainingAmount.toFixed(2)} `}</strong>
            more to get FREE shipping with {vendorName}.
          </Typography>
          <Typography variant="body2" style={{ fontSize: "0.6em" }}>
            Some exclusions may apply. Proceed to checkout or view on
            vendor site for exact shipping estimates.
          </Typography>
        </>
      )}
    </React.Fragment>
  ), [reachedShippingMin, vendorName, remainingAmount]);

  const progressBar = useMemo(() => {
    if (freeShippingMin !== null && freeShippingMin !== undefined) {
      return (
        <LinearProgress
          variant="determinate"
          value={freeShippingProgress}
          sx={{
            height: "16px",
            width: "100%",
            borderRadius: "8px",
            backgroundColor: "#CCCCCC",
            "& .MuiLinearProgress-bar": {
              backgroundColor:
                freeShippingProgress === 100
                  ? "rgb(0,164,0, 0.57)" // actionGreen
                  : "rgba(0, 110, 165, 1)", // blueblue
            },
          }}
        />
      );
    }
    return null;
  }, [freeShippingProgress, freeShippingMin]);

  return (
    <Tooltip
      title={tooltipContent}
      placement="top"
      classes={{ tooltip: styles.customTooltip }}
    >
      {progressBar}
    </Tooltip>
  );
};

export default ShippingProgressBar;
