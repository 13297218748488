import { configureStore } from "@reduxjs/toolkit";
import products from "features/productsSlice";
import carts from "features/cartsSlice";
import user from "features/userSlice";
import styles from "features/styleSlice";
import invoices from "features/invoicesSlice";
import { baseApi } from "features/baseApi";
import webSocketMiddleware from "features/webSocketMiddleware";
import dgiApiWebSocketMiddleware from "features/dgiApiWebSocketMiddleware";
import autoAddReducer from '../features/autoAddSlice';

export const store = configureStore({
  reducer: {
    products: products,
    carts: carts,
    user: user,
    styles: styles,
    [baseApi.reducerPath]: baseApi.reducer,
    invoices: invoices,
    autoAdd: autoAddReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(webSocketMiddleware)
      .concat(dgiApiWebSocketMiddleware)
      .concat(baseApi.middleware),
});

export default store;
