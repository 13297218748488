import React from "react";
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Typography,
} from "@mui/material";
import { getURLColor, constructImageUrl } from "utilities/imageUrlUtils";
import { buildProductUrl } from "utilities/navigation";

const CartInvoiceLineItems = ({ invoiceItems }) => {
  // Group items by style and color
  const groupItems = (items) => {
    const groups = {};
    items.forEach((item) => {
      const key = `${item.dgi_style_sku}_${item.master_color}`;
      if (!groups[key]) {
        const urlColor = getURLColor({ name: item.master_color });
        const imageUrl = constructImageUrl(item.dgi_style_sku, urlColor, 0);

        groups[key] = {
          style: item.style,
          dgi_style_sku: item.dgi_style_sku,
          color: item.master_color,
          imageUrl,
          sizes: [],
        };
      }
      groups[key].sizes.push({
        size: item.size,
        quantity: item.quantity,
        unitPrice: item.unitPrice,
        warehouse: item.warehouse,
      });
    });
    return Object.values(groups);
  };

  const groupedItems = groupItems(invoiceItems);

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          {groupedItems.map((group, groupIndex) => {
            const productUrl = buildProductUrl(group.dgi_style_sku, group.color);

            return (
              <TableRow
                key={groupIndex}
                sx={{
                  borderBottom:
                    groupIndex === groupedItems.length - 1
                      ? "none"
                      : "1px dashed rgba(0, 0, 0, 0.12)",
                }}
              >
                <TableCell
                  sx={{
                    verticalAlign: "top",
                    width: "100px",
                    padding: "8px",
                    borderBottom: "none",
                  }}
                >
                  <a
                    href={productUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    <img
                      src={group.imageUrl}
                      alt=""
                      style={{
                        display: "block",
                        height: "75px",
                        maxHeight: "100%",
                        margin: 0,
                        maxWidth: "100%",
                        width: "auto",
                        cursor: "pointer",
                      }}
                    />
                  </a>
                </TableCell>
                <TableCell
                  sx={{
                    verticalAlign: "top",
                    width: "18%",
                    padding: "8px",
                    borderBottom: "none",
                  }}
                >
                  <a
                    href={productUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      textDecoration: "none",
                      color: "inherit",
                      cursor: "pointer",
                    }}
                  >
                    <Typography
                      component="span"
                      sx={{
                        textDecoration: "none",
                        fontSize: "0.875rem",
                        "&:hover": {
                          textDecoration: "underline",
                        },
                      }}
                    >
                      {group.style}
                    </Typography>
                  </a>
                </TableCell>
                <TableCell
                  sx={{
                    verticalAlign: "top",
                    width: "18%",
                    padding: "8px",
                    borderBottom: "none",
                  }}
                >
                  {group.color}
                </TableCell>
                <TableCell
                  colSpan={2}
                  sx={{
                    verticalAlign: "top",
                    width: "50%",
                    padding: "8px",
                    borderBottom: "none",
                  }}
                >
                  {group.sizes.map((sizeInfo, sizeIndex) => (
                    <div
                      key={sizeIndex}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                          width: "12%",
                        }}
                      >
                        {sizeInfo.size}:
                      </div>
                      <div style={{ width: "4%", textAlign: "right" }}>
                        {sizeInfo.quantity}
                      </div>
                      <div style={{ width: "15%", textAlign: "right" }}>
                        ${sizeInfo.unitPrice.toFixed(2)}
                      </div>
                      <div
                        style={{
                          width: "28%",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {sizeInfo.warehouse}
                      </div>
                    </div>
                  ))}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CartInvoiceLineItems;
