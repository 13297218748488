import React, { useState, useRef, useEffect } from "react";

import {
  Box,
  Menu,
  MenuItem,
  Stack,
  Divider,
  Typography,
  ClickAwayListener,
} from "@mui/material";
import {
  useUserStateContext,
  useDataContext,
  useCheckoutContext,
} from "../App";

import IconButton from "@mui/material/IconButton";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import LogoutIcon from "@mui/icons-material/Logout";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import CloudDoneOutlinedIcon from "@mui/icons-material/CloudDoneOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";

import { useNavigate } from "react-router-dom";
import { usePostHog } from "posthog-js/react";
import { useDispatch } from "react-redux";

const SUPPORT_TEAM_PIC =
  "https://d34c0c1in94n75.cloudfront.net/faq_headshots.svg";

const DIRECT_ARROW_ICON =
  "https://d34c0c1in94n75.cloudfront.net/direct_gray_letter_arrow.png";

function ProfileMenuItem({ item }) {
  if (item.isDivider) {
    return <Divider sx={{ mb: 0 }} />;
  }

  return (
    <MenuItem
      id={item.id}
      key={item.key}
      onClick={item.onClick || undefined}
      disableRipple
      className={`
        flex items-end gap-[9px] 
        md:my-[0.8rem]
        my-[4px] py-[8px] 
        rounded-[5px] hover:bg-lightestGrey ${item.additionalStyling}`}
    >
      {item.icon && <item.icon className="text-typeGrey w-[18px]" />}
      <span className="text-sm mb-[1px]">{item.text}</span>
    </MenuItem>
  );
}

function HelpSection({ handleClose }) {
  const onGetHelp = () => {
    handleClose();
    if (window.Intercom) {
      window.Intercom("show");
    } else {
      console.error("Intercom is not loaded");
    }
  };
  return (
    <div
      className={`
        bg-babyBlue flex items-center justify-around
        cursor-pointer py-[15px]`}
      style={{
        borderTop: "1px solid rgba(0, 0, 0, 0.12)",
      }}
      onClick={onGetHelp}
    >
      <div className="flex flex-col items-start gap-[6px]">
        <Typography className="text-wolfGrey font-bold">
          DGI Support Team
        </Typography>
        <Typography className="text-wolfGrey text-sm">
          Click here to chat now!
        </Typography>
      </div>
      <Box>
        <img
          src={SUPPORT_TEAM_PIC}
          alt="Support Team"
          style={{
            width: "150px",
          }}
        />
      </Box>
    </div>
  );
}

export default function ProfileMenu() {
  const navigate = useNavigate();
  const { signOut, userCreds } = useUserStateContext();
  const { setConnectedVendorCodes } = useDataContext();
  const { setCheckoutFormData, setCanConfirmOrder } = useCheckoutContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const profileRef = useRef(null);
  const posthog = usePostHog();
  const dispatch = useDispatch();

  useEffect(() => {
    const links = [{ href: SUPPORT_TEAM_PIC }, { href: DIRECT_ARROW_ICON }];

    // Create and append link elements
    const linkElements = links.map(({ href }) => {
      const link = document.createElement("link");
      link.rel = "preload";
      link.as = "image";
      link.href = href;
      document.head.appendChild(link);
      return link;
    });

    return () => {
      linkElements.forEach((link) => {
        document.head.removeChild(link);
      });
    };
  }, []);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigateToIntegrations = () => {
    handleClose();
    navigate("/integrations");
  };

  const navigateToShopping = () => {
    // TODO: clear search
    handleClose();
    navigate("/shop");
  };

  const navigateToCart = () => {
    handleClose();
    navigate("/cart");
  };

  const navigateToAccountSettings = () => {
    handleClose();
    navigate("/account-settings/account-details");
  };

  const handleClick = (event) => {
    if (profileRef.current && profileRef.current.contains(event.target)) {
      setAnchorEl(event.currentTarget);
    }
  };

  const signOutUser = async () => {
    // Close the menu first
    handleClose();

    // Call our context's signOut() which calls Auth.signOut()
    await signOut();

    // Optionally navigate, but do a full page reload to reset Amplify’s state
    window.location.href = "/";
  };

  const menuItems = [
    {
      id: "dgi-direct-menu-link",
      key: "dgi_direct",
      text: "DGI Direct",
      icon: () => (
        <img
          src={DIRECT_ARROW_ICON}
          alt="DGI Direct"
          className="w-[18px] mb-[5px]"
        />
      ),
      onClick: () => {
        handleClose();
        navigate("/direct/vendors");
      },
    },
    {
      id: "shoppping-page-menu-link",
      key: "shopping_page",
      text: "Shop",
      icon: ShoppingBagOutlinedIcon,
      onClick: navigateToShopping,
    },
    {
      id: "cart-page-menu-link",
      key: "cart_page",
      text: "My Carts",
      icon: ShoppingCartOutlinedIcon,
      onClick: navigateToCart,
    },
    {
      id: "divider-1",
      key: "divider-1",
      isDivider: true,
    },
    {
      id: "cart-page-menu-link",
      key: "order_history",
      text: "My Order History",
      icon: ImportContactsIcon,
      onClick: () => {
        handleClose();
        navigate("/order-history");
      },
    },
    {
      id: "connections-page-menu-link",
      key: "connect_vendors",
      text: "Connect Vendors",
      icon: CloudDoneOutlinedIcon,
      onClick: navigateToIntegrations,
    },
    {
      id: "account-settings-page-menu-link",
      key: "account_settings",
      text: "Account Settings",
      icon: ManageAccountsOutlinedIcon,
      onClick: navigateToAccountSettings,
    },
    {
      id: "divider-2",
      key: "divider-2",
      isDivider: true,
    },
    {
      id: "logout-menu-link",
      key: "logout",
      text: "Sign Out",
      icon: LogoutIcon,
      onClick: signOutUser,
      additionalStyling: "mb-[8px]",
    },
  ];

  return (
    <div className="flex relative">
      <ClickAwayListener onClickAway={handleClose}>
        <div>
          <Stack
            id="profile-menu-dropdown"
            direction="row"
            alignItems="center"
            onClick={handleClick}
            ref={profileRef}
            style={{ position: "relative" }}
          >
            <Box className="flex items-center pt-[2px] cursor-pointer hover:opacity-80">
              <Box className="hidden md:block padding-8px">
                <span className="text-white whitespace-nowrap">
                  {userCreds.shopName}
                </span>
              </Box>
              <IconButton
                disableRipple
                className="text-white pt-[6px] cursor-pointer"
              >
                {/* Desktop arrow icon */}
                <ArrowDropDownIcon
                  className="w-1.5R h-1.5R text-grayscaleWhite hidden md:block"
                  sx={{
                    transform: open ? "rotate(180deg)" : "rotate(0)",
                    transition: "transform 0.3s ease",
                  }}
                />
                {/* Mobile hamburger/close icon */}
                {open ? (
                  <CloseIcon className="w-6 h-6 text-grayscaleWhite md:hidden" />
                ) : (
                  <MenuIcon className="w-6 h-6 text-grayscaleWhite md:hidden" />
                )}
              </IconButton>
            </Box>

            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: -12,
                horizontal: "right",
              }}
              sx={{
                "& .MuiPaper-root": {
                  pointerEvents: "auto",
                  minWidth: "365px",
                  position: "absolute",
                  "@media (max-width: 768px)": {
                    width: "100vw",
                    maxWidth: "100vw",
                    left: "0px !important",
                    right: "0px !important",
                  },
                },
                "& .MuiMenu-list": {
                  pb: 0,
                },
              }}
              transitionDuration={125}
              disableScrollLock={true}
              style={{ pointerEvents: "none" }}
            >
              <div className="px-[8px]">
                {menuItems.map((item) => (
                  <ProfileMenuItem item={item} key={item.key} />
                ))}
              </div>
              <HelpSection handleClose={handleClose} />
            </Menu>
          </Stack>
        </div>
      </ClickAwayListener>
    </div>
  );
}