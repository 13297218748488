import React, { useState } from "react";
import { useUserStateContext } from "App";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  Checkbox,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InvoiceLineItems from "./InvoiceLineItems.js";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const HighlightedText = ({ text, highlight }) => {
  if (!highlight) return text;

  const parts = text.split(new RegExp(`(${highlight})`, "gi"));
  return parts.map((part, index) =>
    part.toLowerCase() === highlight.toLowerCase() ? (
      <span key={index} style={{ backgroundColor: "#fff3b0" }}>
        {part}
      </span>
    ) : (
      part
    )
  );
};

const formatInvoiceName = (
  isAddedToCart,
  name = "",
  totalQuantity,
  total,
  productionDueDate,
  dgiCatalogMatch,
  reason,
  searchQuery
) => {
  const statusText = isAddedToCart 
    ? "Added to Carts"
    : reason || (!dgiCatalogMatch ? "Error Loading" : "");
  const statusStyles =
    isAddedToCart
      ? {
          backgroundColor: "#e6ffe6",
          color: "#008000",
        }
      : reason || !dgiCatalogMatch
      ? {
          backgroundColor: "#ffe6e6",
          color: "#ff3333",
        }
      : {
          backgroundColor: "transparent",
          color: "transparent",
        };

  const formattedDate = productionDueDate
    ? new Date(productionDueDate).toLocaleDateString("en-US", {
        month: "2-digit",
        day: "2-digit",
      })
    : "N/A";

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: total
          ? "20% 22% 15% 16% 15% 10%"
          : "20% 30% 17% 20% 10%",
        alignItems: "center",
        width: "100%",
        gap: 2,
        paddingRight: "8px",
      }}
    >
      <Typography sx={{ paddingLeft: !total ? "15px" : "0px" }}>
        <HighlightedText
          text={(name || "").replace("\n", " ")}
          highlight={searchQuery}
        />
      </Typography>
      <Box
        sx={{
          ...statusStyles,
          py: 0.5,
          px: 1.5,
          borderRadius: "4px",
          width: "100%",
          textAlign: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: "0.875rem",
            color: statusStyles.color,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {statusText}
        </Typography>
      </Box>
      <Typography
        sx={{ textAlign: total ? "left" : "right", fontSize: "0.875rem", paddingLeft: "10px" }}
      >
        Items: {totalQuantity || "0"}
      </Typography>
      {total && (
        <Typography sx={{ textAlign: "left", fontSize: "0.875rem" }}>
          Total: {total}
        </Typography>
      )}
      <Typography sx={{ textAlign: "right", fontSize: "0.875rem" }}>
        Due: {formattedDate}
      </Typography>
    </Box>
  );
};

const InvoiceAccordion = ({
  invoice,
  selectedInvoices,
  setSelectedInvoices,
  searchQuery,
  manualReviewRequired,
}) => {
  const [dgiCatalogMatch, setDgiCatalogMatch] = useState(true);

  return (
    <Accordion
      key={invoice.invoice_number + " " + invoice.production_due_date}
      sx={{
        mt: 0,
        borderRadius: 0,
        boxShadow: "none",
        borderTop: "1px solid rgba(0, 0, 0, 0.12)",
        "&::before": {
          display: "none",
        },
        "& .MuiPaper-root": {
          borderRadius: 0,
          boxShadow: "none",
        },
        "&:last-of-type": {
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        },
        "&:first-of-type": {
          borderTop: "none",
        },
        "&.Mui-expanded": {
          margin: "0",
        },
        "& .MuiAccordionSummary-root": {
          backgroundColor: selectedInvoices?.[invoice.invoice_id]
            ? "rgba(2, 129, 192, 0.04)"
            : "transparent",
          "&:hover": {
            backgroundColor: "#F5F5F5",
          },
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel-${
            invoice.invoice_number + " " + invoice.date
          }-content`}
          id={`panel-${invoice.invoice_number + " " + invoice.date}-header`}
          sx={{
            position: "sticky",
            top: "0",
            zIndex: "5",
            flex: 1,
            paddingLeft: "0px",
            borderRadius: "0px",
            paddingRight: "8px",
            "& .MuiAccordionSummary-content": {
              margin: "0",
              flex: 1,
              display: "flex",
              alignItems: "center",
            },
            width: "90%",
            height: "48px",
            "&.Mui-expanded": {
              minHeight: "48px",
              height: "48px",
            },
            "&:hover": {
              backgroundColor: "#F5F5F5",
            },
          }}
        >
          {!manualReviewRequired && (
            <Box
              sx={{
                width: "10%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Checkbox
                checked={selectedInvoices?.[invoice.invoice_id] || false}
                onChange={() => {
                  setSelectedInvoices((prev) => ({
                    ...prev,
                    [invoice.invoice_id]: !prev[invoice.invoice_id],
                  }));
                }}
                onClick={(e) => e.stopPropagation()}
                sx={{ borderRadius: "4px" }}
                icon={<CheckBoxOutlineBlankIcon />}
                checkedIcon={<CheckBoxIcon />}
              />
            </Box>
          )}
          {formatInvoiceName(
            invoice.added_to_cart,
            invoice.invoice_number,
            invoice.totals?.total_quantity,
            invoice.total,
            invoice.production_due_date,
            dgiCatalogMatch,
            invoice.dgi_items.find((item) => item.not_found)?.reason ||
              invoice.reason,
            searchQuery
          )}
        </AccordionSummary>
      </Box>
      <AccordionDetails
        sx={{
          boxShadow: "none",
          padding: 0,
          borderTop: "1px dashed rgba(0, 0, 0, 0.12)",
          borderRadius: 0,
          "& .MuiPaper-root": {
            borderRadius: 0,
          },
        }}
      >
        <InvoiceLineItems
          dgiLineItems={invoice.dgi_items}
          invoiceLineItems={invoice.line_items}
          setDgiCatalogMatch={setDgiCatalogMatch}
        />
      </AccordionDetails>
    </Accordion>
  );
};

const InvoiceAccordionStack = ({
  invoices,
  selectedInvoices,
  setSelectedInvoices,
  searchQuery,
  manualReviewRequired,
}) => {
  return (
    <Box
      sx={{
        border: "1px solid lightgray",
        borderLeft: "1px solid rgb(2, 129, 192)",
        marginTop: "0 !important",
      }}
    >
      {invoices?.map((invoice) => (
        <InvoiceAccordion
          key={invoice?.invoice_number}
          invoice={invoice}
          selectedInvoices={selectedInvoices}
          setSelectedInvoices={setSelectedInvoices}
          searchQuery={searchQuery}
          manualReviewRequired={manualReviewRequired}
        />
      ))}
    </Box>
  );
};

const LoadedInvoices = ({
  invoices,
  selectedInvoices,
  setSelectedInvoices,
  searchQuery,
  manualReviewRequired = false,
}) => {
  return (
    <InvoiceAccordionStack
      invoices={invoices}
      selectedInvoices={selectedInvoices}
      setSelectedInvoices={setSelectedInvoices}
      searchQuery={searchQuery}
      manualReviewRequired={manualReviewRequired}
    />
  );
};

export default LoadedInvoices;
