import React from "react";
import { Box, Typography } from "@mui/material";
import LoadedInvoices from "./LoadedInvoices";
import { getURLColor, constructImageUrl } from "utilities/imageUrlUtils";

const ManualInvoices = ({ response, refetch }) => {
  const itemsNotFound = response?.items_not_found || [];

  const getImgUrl = (color, dgiStyle) => {
    if (!color || !dgiStyle) return null;
    const urlColor = getURLColor({ name: color });
    return constructImageUrl(dgiStyle, urlColor, 0);
  };

  if (!response) {
    return null;
  }

  if (!itemsNotFound || itemsNotFound.length === 0) {
    return null;
  }

  return (
    <Box className="flex flex-col space-y-8">
      <Typography variant="h5">Manual Review Required</Typography>
      <LoadedInvoices
        invoices={response["items_not_found"].map((itemGroup) => {
          // Use first item in group for invoice-level details
          const firstItem = itemGroup[0];
          return {
            invoice_id: `manual-${firstItem.style_number}-${firstItem.color}`,
            invoice_number: `Invoice #${firstItem.invoice_number}-${firstItem.style_number}`,
            total: null,
            totals: {
              total_quantity: itemGroup.reduce((sum, item) => sum + item.quantity, 0),
            },
            production_due_date: firstItem.production_due_date,
            // Map all items in the group to dgi_items
            dgi_items: itemGroup.map(item => ({
              master_style: item.style_number,
              master_color: item.color,
              size: item.size || 'N/A',
              quantity: item.quantity,
              not_found: true,
              master_short_description: item.description,
              dgi_style_sku: item.style_number,
              image_url: getImgUrl(item.color, item.style_number)
            })),
            reason: firstItem.reason || 'Item not found in catalog',
          };
        })}
        refetch={refetch}
        manualReviewRequired={true}
      />
    </Box>
  );
};

export default ManualInvoices;
