import React, { useState } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { getURLColor, constructImageUrl } from "utilities/imageUrlUtils";
import { buildProductUrl } from "utilities/navigation";

const LineItemImage = ({ src, productUrl }) => {
  const [imgSrc, setImgSrc] = useState(src);

  const onSrcError = () => {
    setImgSrc(null);
  };

  if (!imgSrc) {
    return <CloseIcon color="error" />;
  }

  const image = (
    <img
      src={src}
      onError={onSrcError}
      alt={null}
      style={{
        display: "block",
        height: "75px",
        maxHeight: "100%",
        margin: 0,
        maxWidth: "100%",
        width: "auto",
        cursor: "pointer",
      }}
    />
  );

  return productUrl ? (
    <a
      href={productUrl}
      target="_blank"
      rel="noopener noreferrer"
      style={{ textDecoration: "none" }}
    >
      {image}
    </a>
  ) : (
    image
  );
};

const InvoiceLineItems = ({
  dgiLineItems,
  setDgiCatalogMatch,
}) => {
  const getImgUrl = (color, dgiStyle) => {
    const urlColor = getURLColor({ name: color });
    return constructImageUrl(dgiStyle, urlColor, 0);
  };

  // Group items by style and color
  const groupedItems = dgiLineItems.reduce((acc, item) => {
    const key = `${item.master_style || item.style_number}-${item.master_color || item.color}`;

    const isFallback = item.not_found;

    if (isFallback) {
      setDgiCatalogMatch(false); // Set to false to indicate an error
    }

    if (!acc[key]) {
      acc[key] = {
        ...item,
        sizes: [{ size: item.size, quantity: item.quantity }],
        isFallback
      };
    } else {
      acc[key].sizes.push({ size: item.size, quantity: item.quantity });
    }
    return acc;
  }, {});

  return (
    <TableContainer 
      component={Paper} 
      sx={{ 
        borderRadius: 0,
        '& .MuiPaper-root': {
          borderRadius: 0
        }
      }}
    >
      <Table>
        <TableBody>
          {Object.values(groupedItems).map((item, index, array) => {
            const productUrl = buildProductUrl(item.dgi_style_sku, item.master_color || item.color);
            const isLastRow = index === array.length - 1;

            return (
              <TableRow
                key={index}
                sx={{
                  "&:not(:first-of-type)": {
                    borderTop: "1px dashed rgba(0, 0, 0, 0.12)",
                  },
                }}
              >
                <TableCell
                  sx={{ verticalAlign: "top", width: "100px", padding: "8px", borderBottom: "none" }}
                >
                  <LineItemImage
                    src={getImgUrl(
                      item?.master_color || item?.color,
                      item?.dgi_style_sku
                    )}
                    productUrl={productUrl}
                  />
                </TableCell>
                <TableCell
                  sx={{ verticalAlign: "top", width: "15%", padding: "8px", borderBottom: "none" }}
                >
                  {!item.not_found && productUrl ? (
                    <a
                      href={productUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        textDecoration: "none",
                        color: "inherit",
                        cursor: "pointer",
                      }}
                    >
                      <Typography
                        component="span"
                        sx={{
                          textDecoration: "none",
                          fontSize: "0.875rem",
                          "&:hover": {
                            textDecoration: "underline",
                          },
                        }}
                      >
                        {item.master_style || item.style_number}
                      </Typography>
                    </a>
                  ) : (
                    <Typography
                      component="span"
                      sx={{
                        fontSize: "0.875rem",
                      }}
                    >
                      {item.master_style || item.style_number}
                    </Typography>
                  )}
                </TableCell>
                <TableCell
                  sx={{ verticalAlign: "top", width: "18%", padding: "8px", borderBottom: "none" }}
                >
                  {item.master_color || item.color}
                </TableCell>
                <TableCell
                  colSpan={2}
                  sx={{ verticalAlign: "top", width: "18%", padding: "8px", borderBottom: "none" }}
                >
                  {item.sizes.map((sizeInfo, idx) => (
                    <div
                      key={idx}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                        }}
                      >
                        {item.not_found && <CloseIcon color="error" />}
                        {sizeInfo.size}:
                      </div>
                      <div>{sizeInfo.quantity}</div>
                    </div>
                  ))}
                </TableCell>
                <TableCell
                  sx={{
                    verticalAlign: "top",
                    width: "33%",
                    padding: "8px",
                    paddingLeft: "18px",
                    borderBottom: "none"
                  }}
                >
                  {item.master_short_description || item.description}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default InvoiceLineItems;
