export const CM_WAREHOUSE_MAP = {
  nc: "Indian Trail, NC",
};

export const AB_WAREHOUSE_MAP = {
  ph: "Harrisburg, PA",
  ma: "Middleboro, MA",
  gd: "Atlanta, GA",
  cc: "Chicago, IL",
  fo: "Orlando, FL",
  td: "Dallas, TX",
  cn: "Fresno, CA",
  kc: "Kansas City, KS",
};

export const SIZE_ORDER_MAP = {
  XXS: 0,
  XS: 1,
  S: 2,
  M: 3,
  L: 4,
  XL: 5,
  "2XL": 6,
  "3XL": 7,
  "4XL": 8,
  "5XL": 9,
};

export const SM_WAREHOUSE_MAP = {
  1: "Seattle, WA",
  2: "Cincinnati, OH",
  3: "Dallas, TX",
  4: "Reno, NV",
  5: "Robbinsville, NJ",
  6: "Jacksonville, FL",
  7: "Minneapolis, MN",
  8: "Backorder",
  12: "Phoenix, AZ",
  31: "Richmond, VA",
};

export const SS_WAREHOUSE_MAP = {
  3: "Robbinsville, NJ",
  12: "Reading, PA",
  11: "West Chester, OH",
  8: "McDonough, GA",
  10: "Lockport, IL",
  2: "Pompano Beach, FL",
  7: "Fort Worth, TX",
  4: "Olathe, KS",
  9: "Reno, NV",
};

export const AS_WAREHOUSE_MAP = {
  as: "Augusta, GA",
};

export const PG_WAREHOUSE_MAP = {
  OXNARD: "Oxnard, CA",
  COLUMBIA: "Columbia, SC",
};

export const ACC_WAREHOUSE_MAP = {
  v: "Virginia Warehouse",
  a4: "A4 Apparel Dropship - CA",
  am: "A4 Apparel Dropship - MO",
  vf: "VF Imagewear Dropship - TN",
  wp: "Whispering Pines Drop - SC",
  sp: "Sierra Pacific Dropship - TX",
};

export const ASC_WAREHOUSE_MAP = {
  nc: "Charlotte, NC",
  ca: "Carson, CA",
};

export const STA_WAREHOUSE_MAP = {
  20: "DALLAS, TX",
  30: "MEMPHIS, TN",
  39: "GILDAN DISTRIBUTION CENTER",
  44: "COLORTONE-Plantation, FL",
};

export const SLC_WAREHOUSE_MAP = {
  A: "Salt Lake City, UT",
  B: "Phoenix, AZ",
};

export const SM_CUTOFF_TIMES = {
  "Seattle, WA": "4:00 PT",
  "Cincinnati, OH": "4:00 ET",
  "Ashland, VA": "2:00 ET",
  "Richmond, VA": "2:00 ET",
  "Dallas, TX": "4:00 CT",
  "Reno, NV": "4:00 PT",
  "Robbinsville, NJ": "4:00 ET",
  "Jacksonville, FL": "4:00 ET",
  "Minneapolis, MN": "4:00 CT",
  "Phoenix, AZ": "4:00 MT",
};

export const ACC_CUTOFF_TIMES = {
  "VIRGINIA WAREHOUSE": "4:30 ET",
};

export const ASC_CUTOFF_TIMES = {
  "Charlotte, NC": "2:00 ET",
  "Carson, CA": "2:00 PT",
};

export const STA_CUTOFF_TIMES = {
  "DALLAS, TX": "4:00 CT",
  "MEMPHIS, TN": "4:00 CT",
};

export const SLC_CUTOFF_TIMES = {
  "Salt Lake City, UT": "4:00 MT",
  "Phoenix, AZ": "4:00 MT",
};

export const CM_WHSE_PICKUP_TIMES = {
  "8:00 AM": "480",
  "8:30 AM": "510",
  "9:00 AM": "540",
  "9:30 AM": "570",
  "10:00 AM": "600",
  "10:30 AM": "630",
  "11:00 AM": "660",
  "11:30 AM": "690",
  "12:00 PM": "720",
  "12:30 PM": "750",
  "1:00 PM": "780",
  "1:30 PM": "810",
  "2:00 PM": "840",
  "2:30 PM": "870",
  "3:00 PM": "900",
  "3:30 PM": "930",
  "4:00 PM": "960",
  "4:30 PM": "990",
  "5:00 PM": "1020",
};

export const checkIfSaleExists = (sizedPricingData) => {
  if (!sizedPricingData) {
    return false;
  }
  return sizedPricingData.some((sizeData) => {
    const [size, salePrice, originalPrice] = sizeData;
    if (
      salePrice === "-" ||
      originalPrice === "-" ||
      salePrice == undefined ||
      originalPrice == undefined
    ) {
      return false;
    }
    const salePriceFigure = parseFloat(
      salePrice.toString().replace(/[^0-9.-]+/g, "")
    );
    const originalPriceFigure = parseFloat(
      originalPrice.toString().replace(/[^0-9.-]+/g, "")
    );
    if (originalPriceFigure > salePriceFigure) {
      return true;
    }
    return false;
  });
};

export const checkIfMultipleEQPs = (sizedPricingData) => {
  if (!sizedPricingData) {
    return false;
  }
  return sizedPricingData.some((dataEntry) => dataEntry[3] || dataEntry[4]);
};

const timezoneOffsets = {
  EST: -300, // -5 hours
  EDT: -240, // -4 hours
  CST: -360, // -6 hours
  CDT: -300, // -5 hours
  MST: -420, // -7 hours
  MDT: -360, // -6 hours
  PST: -480, // -8 hours
  PDT: -420, // -7 hours
  ET: -300, // Same as EST
  CT: -360, // Same as CST
  MT: -420, // Same as MST
  PT: -480, // Same as PST
};

// Get timezone offset in minutes for a given timezone code
export const lookupTimezoneOffset = (timezoneCode) => {
  if (!timezoneCode) return null;
  return timezoneOffsets[timezoneCode?.toUpperCase()];
};

export const getLocalCutoffTime = (warehouseCutoff) => {
  try {
    // Return early if no cutoff provided
    if (!warehouseCutoff) {
      return "N/A";
    }

    // Handle dropshipping warehouses that only use text
    const isLettersAndSpaces = /^[A-Za-z\s]+$/.test(warehouseCutoff);
    if (isLettersAndSpaces) {
      return warehouseCutoff;
    }

    // Split time and timezone with validation
    const parts = warehouseCutoff.split(" ");
    if (parts.length !== 2) {
      const clockTime = /\b(?:0?[1-9]|1[0-2]):\d{2}\b/.test(warehouseCutoff);
      if (clockTime) {
        // no timezone provided
        return warehouseCutoff;
      }
      console.error(
        'Invalid warehouse cutoff format. Expected "time timezone", got:',
        warehouseCutoff
      );
      return warehouseCutoff;
    }

    const [time, sourceTimezone] = parts;

    // Validate timezone code format (both 2 and 3/4 letter codes)
    const validTimezoneCode = /^[A-Z]{2,4}$/.test(sourceTimezone);
    if (!validTimezoneCode) {
      console.error("Invalid timezone code format:", sourceTimezone);
      return warehouseCutoff;
    }

    // Get the local timezone offset
    const localOffset = new Date().getTimezoneOffset();

    const sourceOffset = lookupTimezoneOffset(sourceTimezone);
    if (sourceOffset === undefined) {
      console.error("Unsupported timezone code:", sourceTimezone);
      return warehouseCutoff;
    }

    // Parse time
    const [hours, minutes] = time.split(":").map(Number);
    if (isNaN(hours) || isNaN(minutes)) {
      console.error("Invalid time format:", time);
      return warehouseCutoff;
    }

    // Convert to local time
    const sourceMinutes = hours * 60 + minutes;
    const offsetDiff = sourceOffset - localOffset;
    let localMinutes = sourceMinutes + offsetDiff;

    // Handle day wraparound
    while (localMinutes < 0) localMinutes += 24 * 60;
    while (localMinutes >= 24 * 60) localMinutes -= 24 * 60;

    // Convert back to hours and minutes
    const localHours = Math.floor(localMinutes / 60);
    const localMins = localMinutes % 60;

    // Format time with AM/PM
    const period = localHours >= 12 ? "PM" : "AM";
    const formattedHours = localHours % 12 || 12;
    const formattedMinutes = String(localMins).padStart(2, "0");
    const formattedTime = `${formattedHours}:${formattedMinutes} ${period}`;

    // Get local timezone code based on offset
    const getTimezoneCode = (offset) => {
      const isDST =
        new Date().getTimezoneOffset() <
        new Date(new Date().getFullYear(), 0, 1).getTimezoneOffset();
      const offsetMap = {
        "-240": isDST ? "EDT" : "EST",
        "-300": isDST ? "CDT" : "EST",
        "-360": isDST ? "MDT" : "CST",
        "-420": isDST ? "PDT" : "MST",
        "-480": isDST ? undefined : "PST",
      };
      return offsetMap[offset] || "EST"; // Default to EST if unknown
    };

    const localTimezoneCode = getTimezoneCode(localOffset);
    return `${formattedTime} ${localTimezoneCode}`;
  } catch (error) {
    console.error("Error in getLocalCutoffTime:", {
      input: warehouseCutoff,
      error: error.message,
    });
    return warehouseCutoff;
  }
};

export const sortDeliveryDates = (deliveryDate) => {
  const dateObj = new Date(deliveryDate);
  const currentYear = new Date().getFullYear();
  dateObj.setYear(currentYear);
  return dateObj.getTime();
};

export const calculateDeliveryDate = (
  warehouseName,
  estDeliveryDays,
  rawCutoffTime
) => {
  try {
    // Early returns for missing data
    if (!estDeliveryDays) {
      return "3-7 Days";
    }

    let estDays = estDeliveryDays[warehouseName];
    if (!estDays) {
      return "3-7 Days";
    }

    // Convert estDays to number
    estDays = Number(estDays);
    if (!estDays) {
      console.log(
        `Warning: est days (${estDays}) could not be converted to Number type`
      );
    }

    // Parse cutoff time
    const [cutoffTime, cutoffTimeZone] = rawCutoffTime.split(" ");
    const [cutoffHours, cutoffMinutes] = cutoffTime.split(":").map(Number);

    const today = new Date();
    const localOffset = today.getTimezoneOffset();
    const cutoffOffset = lookupTimezoneOffset(cutoffTimeZone);

    if (cutoffOffset === undefined) {
      console.error("Unsupported timezone code:", cutoffTimeZone);
      return "3-7 Days";
    }

    // Convert cutoff time to local time
    const offsetDiff = cutoffOffset - localOffset;
    const cutoffInMinutes = cutoffHours * 60 + cutoffMinutes;
    let localCutoffMinutes = cutoffInMinutes + offsetDiff;

    // Handle day wraparound
    while (localCutoffMinutes < 0) localCutoffMinutes += 24 * 60;
    while (localCutoffMinutes >= 24 * 60) localCutoffMinutes -= 24 * 60;

    // Check if current time is past cutoff
    const now = new Date();
    const currentMinutes = now.getHours() * 60 + now.getMinutes();

    if (currentMinutes >= localCutoffMinutes) {
      estDays += 1;
    }

    // Get current day of week (0 = Sunday, 6 = Saturday)
    const currentDay = today.getDay();

    // Adjust for weekend ordering
    if (currentDay === 6) {
      // Saturday
      estDays += 2;
    } else if (currentDay === 0) {
      // Sunday
      estDays += 1;
    }

    // Calculate estimated delivery date
    const estDate = new Date(today);
    estDate.setDate(today.getDate() + estDays);

    // Check if estimated date falls on weekend
    const estDayOfWeek = estDate.getDay();
    if (estDayOfWeek === 0 || estDayOfWeek === 6) {
      // Move to next Monday
      while (estDate.getDay() !== 1) {
        estDate.setDate(estDate.getDate() + 1);
      }
    }

    // Format date as MM/DD
    const month = String(estDate.getMonth() + 1).padStart(2, "0");
    const day = String(estDate.getDate()).padStart(2, "0");
    return `${month}/${day}`;
  } catch (e) {
    console.error(
      `Failed to calculate delivery date format for warehouse: ${warehouseName} and cutoff time: ${rawCutoffTime} with error: ${e.message}`
    );
    return "3-7 Days";
  }
};
