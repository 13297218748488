import React, { useState } from "react";
import { Box } from "@mui/material";
import InvoicePage from "./InvoicesPage";
import ActiveCarts from "./ActiveCarts";

const AutoShopPage = () => {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        gap: "100px",
        paddingX: "1rem",
        marginTop: "-1rem",
      }}
    >
      <Box sx={{ width: "50%" }}>
        <InvoicePage />
      </Box>
      <Box sx={{ width: "50%" }}>
        <ActiveCarts />
      </Box>
    </Box>
  );
};

export default AutoShopPage;
