import React, { useState, useEffect } from "react";
import { useUserStateContext } from "App";
import {
  Button,
  CircularProgress,
  Box,
  Skeleton,
  Typography,
  TextField,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import LoadedInvoices from "./LoadedInvoices";
import { useDispatch, useSelector } from "react-redux";
import ManualInvoices from "./ManualInvoices";
import { useWebsocketContext } from "authsignin/AuthedPage";
import {
  selectInvoices,
  selectInvoicesLoading,
  selectInvoicesError,
} from "features/invoicesSlice";
import {
  selectAutoAddLoading,
  selectItemsNotFound,
  selectHasUpdatedCarts,
  selectPOData,
  resetAutoAdd,
  setShowUpdatedCartsAlert,
  selectShowUpdatedCartsAlert,
} from "features/autoAddSlice";
import AlertSnackbar from "alerts/AlertSnackbar";

const InvoiceActionButtons = ({
  refetch,
  selectedInvoices,
  setSelectedInvoices,
}) => {
  const { userCreds } = useUserStateContext();
  const { sendDgiJsonMessage } = useWebsocketContext();
  const isAddingToCarts = useSelector(selectAutoAddLoading);
  const showUpdatedCartsAlert = useSelector(selectShowUpdatedCartsAlert);
  const noInvoicesSelected = Object.keys(selectedInvoices).length === 0;
  const dispatch = useDispatch();

  const handleAddToCart = () => {
    const selectedInvoiceIds = Object.keys(selectedInvoices).filter(
      (invoice_id) => selectedInvoices[invoice_id]
    );
    if (selectedInvoiceIds.length === 0) {
      console.error("Please select at least one invoice to add to cart");
      return;
    }

    sendDgiJsonMessage({
      action: "auto_add",
      invoice_ids: selectedInvoiceIds,
    });

    // reset selected invoices
    setSelectedInvoices({});
  };

  let submitBtnContent = null;
  if (isAddingToCarts) {
    submitBtnContent = <CircularProgress size={24} />;
  } else {
    submitBtnContent = "Add To My Carts";
  }

  return (
    <Box
      className="flex justify-between px-[36px]"
      sx={{ mt: '12px' }}
    >
      <Button
        className="normal-case text-lighterblueblue text-base"
        onClick={refetch}
        sx={{
          border: "1px solid #ADD8E6",
          backgroundColor: "#FFFFFF",
          "&:hover": {
            backgroundColor: "#F5F5F5",
          },
        }}
      >
        Refresh Invoices
      </Button>
      <Button
        className="normal-case bg-lighterblueblue text-dgiWhite text-base hover:bg-denim"
        onClick={handleAddToCart}
        disabled={isAddingToCarts || noInvoicesSelected}
        style={{ textTransform: "none" }}
        sx={{
          "&.Mui-disabled": {
            backgroundColor: "#D3D3D3",
            color: "#A9A9A9",
          },
        }}
      >
        {submitBtnContent}
      </Button>
      <AlertSnackbar
        open={showUpdatedCartsAlert}
        severity="success"
        text="Finished updating your carts!"
        handleClose={() => {
          dispatch(setShowUpdatedCartsAlert(false));
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      />
    </Box>
  );
};

const LoadedInvoiceContent = ({ invoices, refetch }) => {
  const [selectedInvoices, setSelectedInvoices] = useState({});
  const itemsNotFound = useSelector(selectItemsNotFound);
  const [searchQuery, setSearchQuery] = useState("");

  const filteredInvoices = invoices
    .filter((invoice) =>
      invoice.invoice_number.toLowerCase().includes(searchQuery.toLowerCase())
    )
    .sort((a, b) => a.invoice_number.localeCompare(b.invoice_number));

  const handleSelectAll = () => {
    const allSelected = filteredInvoices.reduce((acc, invoice) => {
      acc[invoice.invoice_id] = true;
      return acc;
    }, {});
    setSelectedInvoices(allSelected);
  };

  const handleSelectNone = () => {
    setSelectedInvoices({});
  };

  // Extract selected invoice numbers
  const selectedInvoiceNumbers = filteredInvoices
    .filter((invoice) => selectedInvoices[invoice.invoice_id])
    .map((invoice) => invoice.invoice_number);

  return (
    <>
      <ManualInvoices
        response={{ items_not_found: itemsNotFound }}
        refetch={refetch}
      />
      {invoices.length === 0 ? (
        <Box
          className="flex items-center justify-center"
          sx={{
            height: "300px",
            width: "100%",
            border: "1px solid #ccc",
          }}
        >
          <Typography variant="h6">No Invoices Found</Typography>
        </Box>
      ) : (
        <Box className="flex flex-col" sx={{ height: "calc(100vh - 200px)" }}>
          <Box sx={{ flexShrink: 0 }}>
            <Typography variant="h5">Ready to Order</Typography>
            <Box
              sx={{
                display: "flex",
                gap: 2,
                mb: "12px",
                mt: "20px",
                ml: "2px",
                alignItems: "flex-end",
              }}
            >
              <Typography
                onClick={handleSelectAll}
                sx={{
                  cursor: "pointer",
                  color: "rgb(2, 129, 192)",
                  fontSize: "0.875rem",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
              >
                SELECT ALL
              </Typography>
              <Typography
                onClick={handleSelectNone}
                sx={{
                  cursor: "pointer",
                  color: "rgb(2, 129, 192)",
                  fontSize: "0.875rem",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
              >
                SELECT NONE
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              <TextField
                size="small"
                placeholder="Search invoices..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                autoComplete="off"
                sx={{ 
                  width: '200px',
                  borderRadius: '2px',
                  '& .MuiOutlinedInput-input': {
                    paddingTop: '10px',
                    paddingBottom: '7px',
                  }
                }}
              />
            </Box>
          </Box>
          <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
            <LoadedInvoices
              invoices={filteredInvoices}
              selectedInvoices={selectedInvoices}
              setSelectedInvoices={setSelectedInvoices}
              refetch={refetch}
              searchQuery={searchQuery}
            />
          </Box>
          <Typography sx={{ mt: 1, mb: 0 }}>
            {selectedInvoiceNumbers.length} Invoices Selected: {selectedInvoiceNumbers.join(', ')}
          </Typography>
        </Box>
      )}
      <InvoiceActionButtons
        refetch={refetch}
        selectedInvoices={selectedInvoices}
        setSelectedInvoices={setSelectedInvoices}
      />
    </>
  );
};

const InvoicePage = () => {
  const { userCreds } = useUserStateContext();
  const { sendDgiJsonMessage } = useWebsocketContext();

  const invoices = useSelector(selectInvoices);
  const isLoading = useSelector(selectInvoicesLoading);
  const error = useSelector(selectInvoicesError);

  const fetchInvoices = () => {
    sendDgiJsonMessage({
      action: "get_invoices",
    });
  };

  useEffect(() => {
    // Initial fetch
    fetchInvoices();
    // TODO we will want to fetch POs as well
    // may want to make it a separate message
  }, []);

  return (
    <Box className="flex flex-col space-y-8" sx={{ mt: "1rem" }}>
      {isLoading ? (
        <Box className="flex flex-col">
          <Typography variant="h5" sx={{ mb: "24px" }}>
            Loading Invoices...
          </Typography>
          <Box className="flex flex-col space-y-0.5">
            {[...Array(4)].map((_, index) => (
              <Skeleton
                key={index}
                variant="rectangular"
                height={48}
                width="100%"
              />
            ))}
          </Box>
        </Box>
      ) : (
        <LoadedInvoiceContent invoices={invoices} refetch={fetchInvoices} />
      )}
      {error && (
        <Typography color="error" sx={{ mt: 2 }}>
          {error}
        </Typography>
      )}
    </Box>
  );
};

export default InvoicePage;
