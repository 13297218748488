import React, { useState, useEffect } from "react";

import styles from "./styles.module.scss";
import CheckoutItemPriceEntry from "./CheckoutItemPriceEntry";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import {
  createCartEntry,
  createSwitchWarehouseBody,
} from "../../utilities/createCartEntry";
import { formatPrice } from "../../utilities/formatPrice";
import { Stack, Box, Typography } from "@mui/material";

import endpoints from "../../axios/endpoints";
import axios from "../../axios/prod_client.js";
import ssClient from "../../axios/ssClient.js";
import directClient from "../../axios/directClient";

import { useUserStateContext } from "../../App";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fetchCartSuccess } from "features/cartsSlice";
import { buildProductUrl } from "utilities/navigation";

const capitalizeWords = (str) => {
  if (str == undefined) {
    return str;
  }
  return str
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

const ProductImage = ({ itemImageUrl }) => {
  const [displayProductImage, setDisplayProductImage] = useState(true);

  useEffect(() => {
    if (itemImageUrl && !displayProductImage) {
      setDisplayProductImage(true);
    }
  }, [itemImageUrl]);

  if (!displayProductImage) {
    return null;
  }

  return (
    <img
      className={styles.checkoutItemImage}
      src={itemImageUrl}
      alt={null}
      onError={() => setDisplayProductImage(false)}
    />
  );
};

function CaseQuantityNotification({ caseQtyRequired, caseQty }) {
  if (!caseQtyRequired) {
    return null;
  }
  return (
    <Typography sx={{ fontWeight: "600" }}>
      * Must order in case quantity increments of {caseQty}
    </Typography>
  );
}

export default function CheckoutItem({
  lastItem,
  vendorCode,
  checkoutItemEntries,
  isDirectCart,
}) {
  const [backdropOpen, setBackdropOpen] = useState(false);
  const { userCreds } = useUserStateContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const firstItem = checkoutItemEntries[0];
  const itemStyle = firstItem?.style;
  const itemDescription = firstItem?.description;
  const dgiStyleSKU = firstItem?.dgi_style_sku;
  const masterColor = firstItem?.master_color;
  const itemImageUrl = firstItem?.product_image_url;

  let itemSubtotal = 0.0;
  for (const item of checkoutItemEntries) {
    const additiveTotal =
      item?.totalPrice || Number(item?.unitPrice) * Number(item?.quantity);
    itemSubtotal += additiveTotal;
  }

  async function handleRemoveItem(code, item, onSuccess, onError) {
    setBackdropOpen(true);
    const itemWhse =
      vendorCode === "ab" ||
      vendorCode === "cm" ||
      vendorCode === "acc" ||
      vendorCode === "sm" ||
      vendorCode === "sta"
        ? item.warehouseCode.toLowerCase()
        : item.warehouse;
    const updateItem = isDirectCart
      ? {
          ...item,
          vendorCode: code,
          warehouse: itemWhse,
          updatingEntry: true,
          quantity: 0,
        }
      : createCartEntry(code, itemWhse, "0", null, item);
    const body = {
      items: Array(updateItem),
    };
    if (code === "ss") {
      body["product_url_short"] = item.ss_product_url_short;
    } else if (code === "asc") {
      body["asc_product_id"] = item.asc_product_id;
    }
    return await updateItems(code, body, onSuccess, onError);
  }

  async function handleUpdateWarehouse(
    code,
    item,
    warehouse,
    onSuccess,
    onError,
  ) {
    setBackdropOpen(true);
    const updatePayload = createSwitchWarehouseBody(code, item, warehouse);
    return await updateItems(code, updatePayload, onSuccess, onError);
  }

  async function handleUpdateItem(code, item, quantity, onSuccess, onError) {
    setBackdropOpen(true);
    const itemWhse =
      vendorCode === "ab" ||
      vendorCode === "cm" ||
      vendorCode === "acc" ||
      vendorCode === "sm" ||
      vendorCode === "sta"
        ? item.warehouseCode.toLowerCase()
        : item.warehouse;
    const updateItem = isDirectCart
      ? {
          ...item,
          vendorCode: code,
          warehouse: itemWhse,
          updatingEntry: true,
          quantity,
        }
      : createCartEntry(code, itemWhse, quantity, null, item);
    const body = {
      items: Array(updateItem),
    };
    if (code === "ss") {
      body["product_url_short"] = item.ss_product_url_short;
    } else if (code === "asc") {
      body["asc_product_id"] = item.asc_product_id;
    }
    return await updateItems(code, body, onSuccess, onError);
  }

  const updateItems = async (code, body, onSuccess, onError) => {
    const headers = {
      "User-Identifier": userCreds.cognitoID,
    };
    const params = {
      distributor: isDirectCart ? "direct" : code,
    };
    var client;
    if (code === "ss") {
      client = ssClient;
    } else {
      client = directClient;
    }
    return await client
      .post(endpoints.cart(), body, { params, headers })
      .then(
        (resp) => {
          // and displaying something
          onSuccess();
          dispatch(
            fetchCartSuccess({
              vendorCode: vendorCode,
              cartData: resp?.data?.cartData,
            }),
          );
          setBackdropOpen(false);
          return true;
        },
        // Handle successful update by updating displayCartData
      )
      .catch((error) => {
        onError();
        setBackdropOpen(false);
        console.error(`Error updating items: ${error.message}`);
        return null;
      });
  };

  const navigateToProductPage = (event) => {
    navigate(buildProductUrl(dgiStyleSKU, masterColor));
    event.preventDefault();
  };

  const bottomBorderStyling =
    !lastItem &&
    (isDirectCart ? "1px solid rgba(225, 229, 230, 1)" : "1px solid #959595");

  const caseQtyRequired = checkoutItemEntries.some(
    (entry) => entry.caseQtyRequired,
  );
  const caseQty =
    caseQtyRequired && Number(checkoutItemEntries[0]?.caseQtyIncrement);

  return (
    <div
      className={styles.checkoutItemContainer}
      style={{
        borderBottom: bottomBorderStyling,
      }}
    >
      <div className={styles.detailsAndPricingWrapper}>
        <Stack direction="row" width="40%" spacing={1}>
          <ProductImage itemImageUrl={itemImageUrl} />
          <div className={styles.checkoutItemDetails}>
            <Box
              sx={{
                "&:hover": {
                  "& *": {
                    color: "rgba(0, 110, 165, 1)", // blueblue
                  },
                  cursor: "pointer",
                },
              }}
              onClick={navigateToProductPage}
            >
              <div className={styles.checkoutItemStyle}>
                <span className="text-lg">{itemStyle}</span>
              </div>
              <div className={styles.checkoutItemShortDesc}>
                <span>{itemDescription}</span>
              </div>
            </Box>
            {masterColor && (
              <div className={styles.checkoutItemColor}>
                <span className="text-fadedGray">Color:</span>
                <span> {capitalizeWords(masterColor)}</span>
              </div>
            )}
          </div>
        </Stack>
        <Stack direction="column" gap="20px" sx={{ width: "60%" }}>
          <CaseQuantityNotification
            caseQtyRequired={caseQtyRequired}
            caseQty={caseQty}
          />
          {checkoutItemEntries.map((itemEntry, index) => (
            <CheckoutItemPriceEntry
              key={`${itemEntry.dgi_style_sku}-${itemEntry.quantity}-${itemEntry.warehouse}-${index}`}
              vendorCode={vendorCode}
              itemEntry={itemEntry}
              isDirectCart={isDirectCart}
              handleUpdateItem={handleUpdateItem}
              handleUpdateWarehouse={handleUpdateWarehouse}
              handleRemoveItem={handleRemoveItem}
              setBackdropOpen={setBackdropOpen}
            />
          ))}
        </Stack>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.modal + 1 }}
        open={backdropOpen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className={styles.entryTotalPriceWrapper}>
        <span className="text-charcoal">{formatPrice(itemSubtotal)}</span>
      </div>
    </div>
  );
}
