import React from "react";
import { Typography, Box } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CartInvoice from "./CartInvoice";
import { calculateDiscountDSTotalPrice } from "pages/cart/VendorCart";

function VendorLogo({ vendorCode, vendorName }) {
  const vendorLogoSrc = `https://d34c0c1in94n75.cloudfront.net/${vendorCode}_logo.png`;
  return (
    <img
      src={vendorLogoSrc}
      alt={vendorName}
      style={{ height: "30px", objectFit: "contain" }}
    />
  );
}

const ActiveVendorCart = ({ vendor }) => {
  const vendorData = vendor[1];
  const vendorName = vendorData.name;
  const vendorCode = vendor[0];
  const isDirectCart = vendorData.isDirectCart;
  const cartItems = vendorData.items;
  const totalPrice = cartItems.reduce(
    (sum, item) =>
      sum + (item.totalPrice || item.unitPrice * item.quantity || 0),
    0
  );
  const freeShippingMin = vendor[1].freeShippingMin;
  const discountDSTotalPrice = calculateDiscountDSTotalPrice(
    vendorData,
    vendorCode,
    isDirectCart
  );

  // Calculate total quantity
  const totalQuantity = vendor[1].invoices.reduce((total, invoice) => {
    return total + invoice.invoiceItems.reduce((sum, item) => sum + item.quantity, 0);
  }, 0);

  return (
    <Accordion
      elevation={0}
      defaultExpanded
      sx={{
        "&:first-of-type": {
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        },
        "&:last-of-type": {
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        },
        margin: 0,
        borderRadius: 0,
        backgroundColor: "transparent",
        borderLeft: "1px solid rgb(2,129,192)",
        "& .MuiPaper-root": {
          borderRadius: 0,
        },
        "& .MuiAccordion-root": {
          borderRadius: 0,
        },
        "& .MuiAccordion-region": {
          borderRadius: 0,
        },
        "&::before": {
          display: "none",
        },
        "&.Mui-expanded": {
          marginY: 0,
        },
        "&:hover": {
          boxShadow:
            "0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07)",
        },
      }}
    >
      <AccordionSummary
        expandIcon={<KeyboardArrowDownIcon className="text-charcoal" />}
        sx={{
          borderRight: "1px solid #e0e3e9",
          borderTop: "1px solid #e0e3e9",
          position: "sticky",
          top: "-1px",
          zIndex: "5",
          backgroundColor: "rgb(245, 247, 250)",
          minHeight: "48px !important",
          height: "48px",
          paddingX: "8px",
          "&:hover": {
            backgroundColor: "#F5F5F5",
          },
          "& .MuiAccordionSummary-content": {
            margin: "0 !important",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            marginRight: "8px",
          }}
        >
          <Box
            sx={{
              width: "200px",
              padding: "2px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <VendorLogo vendorCode={vendorCode} vendorName={vendorName} />
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "20px",
              justifyContent: "flex-end",
            }}
          >
            <Box sx={{ width: "80px" }}>
              <Typography>
                <span style={{ color: "rgba(0, 0, 0, 0.6)" }}>Items:</span> {totalQuantity}
              </Typography>
            </Box>
            <Box sx={{ width: "140px" }}>
              <Typography sx={{ textAlign: "right" }}>
                <span style={{ color: "rgba(0, 0, 0, 0.6)" }}>Subtotal:</span> ${totalPrice.toFixed(2)}
              </Typography>
            </Box>
          </Box>
        </Box>
      </AccordionSummary>

      <AccordionDetails
        sx={{
          borderRight: "1px solid rgba(225, 229, 230, 1)",
          borderBottom: "1px solid rgba(225, 229, 230, 1)",
          display: "flex",
          flexDirection: "column",
          padding: 0,
        }}
      >
        {vendor[1].invoices.map((data) => {
          return (
            <CartInvoice
              key={`cart-invoice-${data.invoiceNumber}`}
              invoiceNumber={data.invoiceNumber}
              invoiceItems={data.invoiceItems}
              total={data.totalPrice}
            />
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
};

export default ActiveVendorCart;
