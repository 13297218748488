import parseReceivedMessage from "features/parseReceivedMessage";
import { Auth } from "aws-amplify";
import {
  fetchInvoicesSuccess,
  fetchInvoicesError,
  fetchInvoicesStart,
  updateInvoicesAddedToCart,
  updateInvoicesInProgress
} from "./invoicesSlice";
import { autoAddSuccess, autoAddError, autoAddStart } from "./autoAddSlice";
import { fetchCartSuccess } from "./cartsSlice";
import { tagCartItemsWithInvoiceIDs } from "./cartsSlice";

const WS_API = {
  development: "wss://api-dev.dgiapparel.com/ws",
  staging: "wss://api-dev.dgiapparel.com/ws",
  production: "wss://api-prod.dgiapparel.com/ws",
};

const SOCKET_API =
  process.env.REACT_APP_LOCAL_DEV === "true"
    ? "ws://127.0.0.1:8000/ws"
    : WS_API[process.env.NODE_ENV] || WS_API.production;

const dgiApiWebSocketMiddleware = (store) => {
  let socket = null;
  let last_socket_user_id = null;
  let pendingMessages = [];
  let retryCount = 0;
  let maxRetries = 5;
  let baseDelay = 1000; // Initial delay of 1 second

  const onOpen = () => {
    console.log("DGI API WebSocket connected");
    console.log(socket);
    // Reset retry count on successful connection
    retryCount = 0;
    // Ensure socket is fully open before sending messages
    if (socket.readyState === WebSocket.OPEN) {
      // Send pending messages when the connection is opened
      pendingMessages.forEach((message) => {
        socket.send(JSON.stringify(message));
      });
      pendingMessages = [];
    } else {
      console.log("Socket not yet ready, keeping messages in pending queue");
    }
  };

  const onClose = async (event) => {
    console.log("DGI API WebSocket disconnected");
    console.log(event);
    if (
      event?.reason !== "disconnect event connection close" &&
      last_socket_user_id != null
    ) {
      if (retryCount < maxRetries) {
        const delay = Math.min(baseDelay * Math.pow(2, retryCount), 30000); // Cap at 30 seconds
        console.log(
          `DGI API WebSocket reconnecting in ${delay}ms (attempt ${
            retryCount + 1
          }/${maxRetries})`,
        );
        retryCount++;

        setTimeout(async () => {
          console.log("DGI API WebSocket reconnecting");
          const session = await Auth.currentSession();
          const jwtToken = session.idToken.jwtToken;
          socket = new WebSocket(
            SOCKET_API + `?user_id=${last_socket_user_id}&token=${jwtToken}`,
          );
          socket.onopen = onOpen;
          socket.onclose = onClose;
          socket.onmessage = onMessage;
        }, delay);
      } else {
        console.log("DGI API WebSocket max retries reached");
      }
    }
  };

  const onMessage = (event) => {
    const { dispatch } = store;
    const message = JSON.parse(event.data);
    // Handle invoice messages
    if (message.invoices !== undefined) {
      if (message.error) {
        dispatch(fetchInvoicesError(message.error));
      } else {
        dispatch(fetchInvoicesSuccess(message.invoices || []));
      }
    }

    // Handle auto-add messages
    if (
      message.po_data !== undefined ||
      message.items_not_found !== undefined ||
      message.line_items !== undefined
    ) {
      if (message.error) {
        dispatch(autoAddError(message.error));
      } else {
        // Update carts with the response data
        for (const [vendorCode, respData] of Object.entries(message)) {
          if (
            vendorCode === "items_not_found" ||
            vendorCode === "line_items" ||
            vendorCode === "po_data"
          )
            continue;
          dispatch(
            fetchCartSuccess({
              vendorCode,
              cartData: {
                ...respData.cartData,
                items: tagCartItemsWithInvoiceIDs(
                  vendorCode,
                  respData.cartData?.items,
                  [...store.getState().autoAdd.line_items, ...message.line_items]
                ),
              },
            }),
          );
        }
        // Update items not found
        dispatch(autoAddSuccess(message));
        // Update invoices added to cart
        dispatch(updateInvoicesAddedToCart());
      }
    }

    // Dispatch general websocket message for other handlers
    dispatch({ type: "DGI_API_WEBSOCKET_MESSAGE", payload: message });
  };

  return (next) => (action) => {
    switch (action.type) {
      case "DGI_API_WEBSOCKET_CONNECT":
        const user_id = action.payload?.user_id;
        if (last_socket_user_id != null && user_id === last_socket_user_id) {
          break;
        }
        if (socket !== null) {
          console.log(socket);
          console.log(last_socket_user_id);
          socket.close(1000, "connection close");
        }
        // Get JWT token and add it to the WebSocket URL
        Auth.currentSession().then((session) => {
          const jwtToken = session.idToken.jwtToken;
          socket = new WebSocket(
            SOCKET_API + `?user_id=${user_id}&token=${jwtToken}`,
          );
          last_socket_user_id = user_id;
          socket.onopen = onOpen;
          socket.onclose = onClose;
          socket.onmessage = onMessage;
        });
        break;

      case "DGI_API_WEBSOCKET_DISCONNECT":
        console.log("Received disconnect action");
        if (socket !== null) {
          socket.close(1000, "disconnect event connection close");
        }
        socket = null;
        last_socket_user_id = null;
        pendingMessages = [];
        break;

      case "DGI_API_WEBSOCKET_SEND":
        if (action.payload.action === "get_invoices") {
          store.dispatch(fetchInvoicesStart());
        } else if (action.payload.action === "auto_add") {
          store.dispatch(autoAddStart());
          store.dispatch(updateInvoicesInProgress(action.payload.invoice_ids));
          // Get the current session and add the access token to the payload
          Auth.currentSession().then((session) => {
            const payload = {
              ...action.payload,
              authorization: session.getAccessToken().getJwtToken(),
            };
            if (socket === null || socket.readyState !== WebSocket.OPEN) {
              pendingMessages.push(payload);
            } else {
              socket.send(JSON.stringify(payload));
            }
          });
          break;
        }
        if (socket === null || socket.readyState !== WebSocket.OPEN) {
          // If the connection is not open, add the message to the pending messages array
          pendingMessages.push(action.payload);
        } else {
          // If the connection is open, send the message immediately
          socket.send(JSON.stringify(action.payload));
        }
        break;

      default:
        return next(action);
    }
  };
};

export default dgiApiWebSocketMiddleware;
