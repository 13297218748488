import React from "react";
import { Typography, Box } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CartInvoiceLineItems from "./CartInvoiceLineItems";

const CartInvoice = ({ invoiceNumber, invoiceItems, total }) => {
  // Calculate total quantity
  const totalQuantity = invoiceItems.reduce((sum, item) => sum + item.quantity, 0);

  return (
    <Accordion
      defaultExpanded={true}
      elevation={0}
      sx={{
        "&:first-of-type": {
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        },
        margin: 0,
        borderRadius: 0,
        backgroundColor: "transparent",
        "&::before": {
          display: "none",
        },
        "&.Mui-expanded": {
          marginY: 0,
        },
      }}
    >
      <AccordionSummary
        expandIcon={<KeyboardArrowDownIcon />}
        sx={{
          borderTop: "1px solid #e0e3e9",
          borderBottom: "none",
          backgroundColor: "rgb(245, 247, 250)",
          minHeight: "48px !important",
          height: "48px",
          paddingRight: "8px",
          paddingLeft: "16px",
          "& .MuiAccordionSummary-content": {
            margin: "0 !important",
          },
          position: "sticky",
          top: "47px",
          zIndex: "4",
          "&:hover": {
            backgroundColor: "#F5F5F5",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            marginRight: "8px",
          }}
        >
          <Box sx={{ width: "200px" }}>
            <Typography>
              {invoiceNumber === "Unassigned Items"
                ? "Other Items"
                : `Invoice #${invoiceNumber}`}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "20px",
              justifyContent: "flex-end",
            }}
          >
            <Box sx={{ width: "80px" }}>
              <Typography>
                <span style={{ color: "rgba(0, 0, 0, 0.6)" }}>Items:</span> {totalQuantity}
              </Typography>
            </Box>
            <Box sx={{ width: "140px" }}>
              <Typography sx={{ textAlign: "right" }}>
                ${total.toFixed(2)}
              </Typography>
            </Box>
          </Box>
        </Box>
      </AccordionSummary>

      <AccordionDetails
        sx={{
          boxShadow: "none",
          padding: 0,
          borderTop: "1px dashed rgba(0, 0, 0, 0.12)",
        }}
      >
        <CartInvoiceLineItems invoiceItems={invoiceItems} />
      </AccordionDetails>
    </Accordion>
  );
};

export default CartInvoice;
