import React, { useMemo } from "react";
import { Typography, Box, Button } from "@mui/material";
import ActiveVendorCart from "./ActiveVendorCart";
import { Link } from "react-router-dom";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useSelector } from "react-redux";
import { selectAutoAddLoading } from "features/autoAddSlice";

const exportToPDF = (processedVendorData) => {
  const html = `
    <!DOCTYPE html>
    <html>
      <head>
        <title>Cart Export</title>
        <style>
          body { font-family: Arial, sans-serif; padding: 20px; }
          .vendor { margin: 20px 0; page-break-inside: avoid; }
          .invoice { margin: 15px 0; border-top: 1px solid #eee; }
          table { border-collapse: collapse; width: 100%; margin: 10px 0; }
          th { text-align: left; border-bottom: 1px solid #ddd; padding: 8px 4px; }
          td { padding: 8px 4px; }
          .total { text-align: right; font-weight: bold; padding: 8px 4px; }
        </style>
      </head>
      <body>
        ${processedVendorData
          .map(
            (vendor) => `
          <div class="vendor">
            <h2>${vendor.vendorName}</h2>
            ${vendor.invoices
              .map(
                (invoice) => `
              <div class="invoice">
                <h3>${
                  invoice.invoiceNumber === "Unassigned Items"
                    ? "Unassigned Items"
                    : `Invoice #${invoice.invoiceNumber}`
                }</h3>
                <table>
                  <thead>
                    <tr>
                      <th>Style</th>
                      <th>Color</th>
                      <th>Size</th>
                      <th>Quantity</th>
                      <th>Unit Price</th>
                      <th>Warehouse</th>
                    </tr>
                  </thead>
                  <tbody>
                    ${invoice.invoiceItems
                      .map(
                        (item) => `
                    <tr>
                      <td>${item.style}</td>
                      <td>${item.master_color}</td>
                      <td>${item.size}</td>
                      <td>${item.quantity}</td>
                      <td>$${item.unitPrice.toFixed(2)}</td>
                      <td>${item.warehouse}</td>
                    </tr>
                  `
                      )
                      .join("")}
                  </tbody>
                </table>
                <div class="total">Total: $${invoice.totalPrice.toFixed(
                  2
                )}</div>
              </div>
            `
              )
              .join("")}
          </div>
        `
          )
          .join("")}
      </body>
    </html>
  `;

  const blob = new Blob([html], { type: "text/html" });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = "cart-export.html";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
};

const exportToCSV = (processedVendorData) => {
  // Create CSV header
  let csv =
    "Vendor,Invoice Number,Style,Color,Size,Quantity,Unit Price,Total Price,Warehouse\n";

  // Add data rows
  processedVendorData.forEach((vendor) => {
    vendor.invoices.forEach((invoice) => {
      invoice.invoiceItems.forEach((item) => {
        csv += `${vendor.vendorName},`;
        csv += `${invoice.invoiceNumber},`;
        csv += `${item.style},`;
        csv += `${item.master_color},`;
        csv += `${item.size},`;
        csv += `${item.quantity},`;
        csv += `$${item.unitPrice.toFixed(2)},`;
        csv += `$${invoice.totalPrice.toFixed(2)},`;
        csv += `${item.warehouse}\n`;
      });
    });
  });

  // Create and trigger download
  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.setAttribute("href", url);
  link.setAttribute("download", "cart-export.csv");
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};

const ActiveCarts = () => {
  const allCarts = useSelector((state) => state.carts.carts);
  const vendors = Object.entries(allCarts);
  const isAddingToCarts = useSelector(selectAutoAddLoading);

  // Memoize processedVendorData to prevent unnecessary recalculations
  const processedVendorData = useMemo(() => {
    return vendors.map(([vendorCode, vendorData]) => {
      // Group by invoice (same as before)
      const itemGroupings = (vendorData.items || []).reduce((acc, item) => {
        const invoiceNum = item.invoiceNumber || "Unassigned Items";
        if (!acc[invoiceNum]) {
          acc[invoiceNum] = [];
        }
        acc[invoiceNum].push(item);
        return acc;
      }, {});

      const groupedByInvoice = Object.entries(itemGroupings).map(
        ([invoiceNum, items]) => ({
          invoiceNumber: invoiceNum,
          invoiceItems: items,
          totalPrice: items.reduce(
            (sum, item) => sum + (item.totalPrice || item.unitPrice * item.quantity || 0),
            0
          ),
        })
      );

      // Check if first item has direct_vendor field
      const isDirectCart = vendorData.items?.[0]?.direct_vendor || false;

      return {
        vendorCode,
        vendorName: vendorData.name,
        freeShippingMin: vendorData.freeShippingMin || 200,
        isDirectCart,
        invoices: groupedByInvoice,
      };
    });
  }, [vendors]);

  const memoizedTypography = useMemo(
    () => <Typography variant="h5">Shopping Carts</Typography>,
    []
  );

  const memoizedButtons = useMemo(
    () => (
      <Box>
        <Button
          variant="outlined"
          startIcon={<FileDownloadIcon />}
          onClick={() => exportToCSV(processedVendorData)}
          sx={{ mr: 1 }}
        >
          Export CSV
        </Button>
        <Button
          variant="outlined"
          startIcon={<FileDownloadIcon />}
          onClick={() => exportToPDF(processedVendorData)}
        >
          Export PDF
        </Button>
      </Box>
    ),
    [processedVendorData]
  );

  // Memoize sx props to prevent them from being recreated on each render
  const memoizedSxMainBox = useMemo(
    () => ({
      width: "100%",
      maxHeight: "calc(100vh - 180px)",
      display: "flex",
      flexDirection: "column",
      gap: "20px",
    }),
    []
  );

  const memoizedSxHeaderBox = useMemo(
    () => ({
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      // marginBottom: "25px",
    }),
    []
  );

  const memoizedSxOverlayBox = useMemo(
    () => ({
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.1)",
      zIndex: 1000,
      borderRadius: "5px",
    }),
    []
  );

  const memoizedSxFooterBox = useMemo(
    () => ({
      mt: "auto",
      textAlign: "center",
    }),
    []
  );

  return (
    <Box sx={{ ...memoizedSxMainBox, mt: "1rem" }}>
      <Box sx={memoizedSxHeaderBox}>
        {memoizedTypography}
        {memoizedButtons}
      </Box>

      <Box 
        sx={{
          flexGrow: 1,
          overflow: "auto",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "20px"
        }}
      >
        {processedVendorData.map((vendor, index) => {
          //memeoize the vendor prop to ensure it remains stable across renders
          const memoizedVendor = [
            vendor.vendorCode,
            {
              name: vendor.vendorName,
              items: vendor.invoices.flatMap((inv) => inv.invoiceItems),
              invoices: vendor.invoices,
              freeShippingMin: vendor.freeShippingMin,
              isDirectCart: vendor.isDirectCart,
            },
          ];

          return (
            <div
              key={`${vendor.vendorCode}-${index}`}
              style={{ position: "relative" }}
            >
              {isAddingToCarts && <Box sx={memoizedSxOverlayBox} />}
              <ActiveVendorCart key={index} vendor={memoizedVendor} />
            </div>
          );
        })}
      </Box>
    </Box>
  );
};

export default ActiveCarts;
